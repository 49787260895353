import React from "react";
import { Link } from "gatsby"
import { withTranslation } from "react-i18next";
import GoogleMaps from "../GoogleMaps";
import Modal from '../Modal'
import SmartForm from '../SmartForm'
import Mailto from "react-protected-mailto"

import './contact.scss'
import SocialShare from "../SocialShare";
class ContactPage extends React.Component {

    render() {
    
        const { t } = this.props
        const dateFormat = t('dateFormat')

        return(
            <div className="contact-us-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 d-none d-lg-block contact-address">
                            <div className="contact-information">
                                <ul>
                                    <li>
                                        <h5>{t('address')}</h5>
                                        <ul>
                                            <li>
                                                {t('tiliana_address')}
                                            </li>
                                            <li>
                                                NTAK : SZ19000385
                                            </li>
                                            <li>
                                                Szálláshely típusa - szálloda
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>{t('directorate')}</h5>
                                        <ul>
                                            <li>
                                                <i class="fa fa-phone"></i><Mailto tel='+36 1 391 0027' />
                                            </li>
                                            <li>
                                                <i class="fa fa-envelope"></i>
                                                <Mailto
                                                    email='director@hoteltiliana.hu'
                                                    headers={
                                                        {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                    }/>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>{t('reception')}</h5>
                                        <ul>
                                            <li>
                                                <i class="fa fa-phone"></i><Mailto tel='+36 1 391 0027' />
                                            </li>
                                            <li>
                                                <i class="fa fa-envelope"></i>
                                                <Mailto
                                                    email='reception@hoteltiliana.hu'
                                                    headers={
                                                        {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                    }/>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>{t('sales-noun')}</h5>
                                        <ul>
                                            <li>
                                                <i class="fa fa-phone"></i><Mailto tel='+36 1 416 99 99' />
                                            </li>
                                            <li>
                                                <i class="fa fa-envelope"></i>
                                                <Mailto
                                                    email='sales@hoteltiliana.hu'
                                                    headers={
                                                        {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                    }/>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>{t('happennings')}</h5>
                                        <ul>
                                            <li>
                                                <i class="fa fa-phone"></i>
                                                <Mailto tel='+36 70 666 6668' />
                                            </li>
                                            <li>
                                                <i class="fa fa-phone"></i>
                                                <Mailto tel='+36 70 322 3333' />
                                            </li>
                                            <li>
                                                <i class="fa fa-envelope"></i>
                                                <Mailto
                                                    email='event@homokyhotels.hu'
                                                    headers={
                                                        {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                    }/>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>{t('etalon')}</h5>
                                        <ul>
                                            <li>
                                                <i class="fa fa-phone"></i>
                                                <Mailto tel='+36 70 409 0000' />
                                            </li>
                                        </ul>
                                        <SocialShare title={t('get_in_touch_with_hotel_tiliana')} className="social-icons" />
                                    </li>
                                </ul>
                                <img className="homoky_logo" src="/images/logo/hh.svg" alt="Homoky hotels logo" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="contact-form-container">
                                        <div className="contact-form-title">
                                            <h3>{t('get-in-touch')}</h3>
                                        </div>
                                        <div id="contact-form">
                                            <div className="row">
                                                <SmartForm 
                                                    successTitle={t('your_message_has_been_sent_successfully')}
                                                    successMessage={t('our_colleagues_will_contact_you_as_soon_as_possible')} 
                                                    formClassName="contact-form" 
                                                    formName="contact" 
                                                    mailSubject="Tiliana kapcsolatfelvétel"
                                                    stateProps={["name", "email", "phone", "message"]}
                                                >
                                                    <div className="col-md-12">
                                                        <div className="single-input form-group">
                                                            <input name="name" placeholder={t('my_name')} type="text" required />
                                                        </div>  
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="single-input form-group">
                                                            <input name="email" type="email" placeholder={t('my_email')} required />
                                                        </div>  
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="single-input form-group">
                                                            <input name="phone" type="phone" placeholder={t('my_phone')} required />
                                                        </div>  
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="single-input form-group">
                                                            <textarea className="form-control" name="message" placeholder={t('my_message')} required />
                                                        </div>  
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button className="sent-btn form-group col-lg-auto" type="submit">
                                                            {t('send_message')}
                                                        </button>
                                                    </div>
                                                </SmartForm>
                                            </div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>
                            <div class="row">
                                <div className="col-lg-5 d-lg-none d-xl-none contact-address">
                                    <div className="contact-information">
                                        <ul>
                                            <li>
                                                <h5>{t('address')}</h5>
                                                <ul>
                                                    <li>
                                                        {t('tiliana_address')}
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h5>{t('directorate')}</h5>
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-phone"></i><Mailto tel='+36 1 391 0027' />
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-envelope"></i>
                                                        <Mailto
                                                            email='director@hoteltiliana.hu'
                                                            headers={
                                                                {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                            }/>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h5>{t('reception')}</h5>
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-phone"></i><Mailto tel='+36 1 391 0027' />
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-envelope"></i>
                                                        <Mailto
                                                            email='reception@hoteltiliana.hu'
                                                            headers={
                                                                {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                            }/>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h5>{t('sales-noun')}</h5>
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-phone"></i>
                                                        <Mailto tel='+36 1 416 99 99' />
                                                    </li>
                                                    <li>
                                                        <i class="fa fa-envelope"></i>
                                                        <Mailto
                                                            email='sales@hoteltiliana.hu'
                                                            headers={
                                                                {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                            }/>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h5>{t('happennings')}</h5>
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-envelope"></i>
                                                        <Mailto
                                                            email='event@homokyhotels.hu'
                                                            headers={
                                                                {subject:'Megkeresés a tiliana.hu weboldalról'}
                                                            }/>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h5>{t('etalon')}</h5>
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-phone"></i>
                                                        <Mailto tel='+36 70 409 0000' />
                                                    </li>
                                                </ul>
                                                <SocialShare title={t('get_in_touch_with_hotel_tiliana')} className="social-icons" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div className="google-maps">
                                        <GoogleMaps mapId="1dT6IaEnbSzDEvksLKbfAD1Q-pPv_wHjP" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
    }
}

export default withTranslation()(ContactPage)